export default {
  appName: 'Team Organization & Management',
  poweredBy: 'realizzato da',
  paginationPerPage: 'per pagina',
  paginationOf: 'di',
  reload: 'Aggiorna',
  exportList: 'Esporta lista',
  acceptOrDeclineRequest: 'Accetta o rifiuta la richiesta',
  active: 'Attivo',
  updateSuccess: 'Aggiornamento avvenuto con successo',
  deletedSuccess: 'Cancellazione avvenuta con successo',
  deletedFailed: 'Non è stato possibile cancellare l\'elemento',
  activityPlanner: 'Pianificatore di attività',
  activityType: 'Tipo di attività',
  operationType: 'Tipo di intervento',
  addFilter: 'aggiungi filtro',
  additionalInfo: 'Note',
  addNewPassword: 'Nuova password',
  addProject: '+',
  allClockInsConfirmed: 'Tutte le timbrature sono state confermate',
  allowance: 'Indennità',
  accountIsAnEmployee: 'Il tuo profilo non è di amministrazione!',
  accountIsAnEmployeeReferTo: 'Per favore utilizza l\'applicazione di timbratura',
  accountMissingConfig: 'Il tuo profilo non è configurato correttamente!',
  accountMissingConfigReferTo: 'Per favore contatta il tuo amministratore di sistema',
  approve: 'Approva',
  areYouSureYouWantToConfirmTimes: 'Così facendo non potrai più aggiungere o modificare altre timbrature',
  backOffice: 'Back Office',
  backOfficeTitle: 'Back Office',
  badCredentials: 'Username o password non validi',
  userIsDisabled: 'L\'utente è disabilitato',
  bituminousConglomerate: 'Conglomerato bituminoso',
  cancel: 'Indietro',
  cannotDeleteCategory: 'Impossibile cancellare la categoria selezionata. Verificare che non ci siano timbrature o pianificature associate ad essa',
  cannotDeleteLocalUnit: 'Impossibile cancellare l\'unità locale selezionata. Verificare che non ci siano cumuli associate ad essa',
  cannotDeleteManufacturer: 'Impossibile cancellare il produttore / trasportatore. Verificare che non ci siano cumuli o movimenti associati ad esso',
  carrier: 'Trasportatore',
  categories: 'Impianti',
  category: 'Impianto',
  certificate: 'Certificato analisi',
  certificates: 'Certificati',
  certificateColumn: 'Certificato',
  clockInTitle: 'Timbrature',
  ok: 'Ho capito',
  close: 'Chiudi',
  closePile: 'Chiudi',
  keyCode: 'Codice chiave',
  code: 'Codice interno',
  confirm: 'Conferma',
  confirmAsIs: 'conferma così com\'è',
  confirmDeactivation: 'Chiudere Cumulo?',
  confirmDeletion: 'Cancellare Cumulo?',
  confirmed: 'Confermati',
  confirmedAndNot: 'Confermati e non',
  confirmTodaysTimes: 'conferma tutte le timbrature',
  copyFromPreviousDay: 'da precedente',
  copyWarning: `Seems like there 2 schedules in the copy phase. If you would like to continue through the application please close the windows or click the button below to exit out of the copy phase.`,
  create: 'Crea',
  customDates: 'Date Personalizzate',
  dashboardTitle: 'Dashboard',
  date: 'Data',
  dateFilter: 'Filtra per Data',
  dateFrom: 'Dalla data',
  dateToCopy: 'Data sorgente',
  dateToProgram: 'Data destinazione',
  dayOff: 'Giorno libero',
  dayPart: 'Parte del giorno',
  ddtRegistryId: 'N° DDT',
  default: 'Predefinito',
  deny: 'Rifiuta',
  delete: 'Cancella',
  remove: 'Rimuovi',
  deleteError: 'Errore di cancellazione',
  destination: 'Destinazione',
  description: 'Descrizione',
  didDriveVan: 'Hai guidato un furgone?',
  didWorkOnProjectOrCategory: 'Hai lavorato a una commessa o in un impianto?',
  download: 'scarica',
  downloadCertificates: 'Scarica certificati',
  downloadDdt: 'Scarica DDT',
  driving: 'Guida',
  droveHours: 'Ore alla guida',
  droveOtherVehicles: 'Hai fatto rifornimento con un altro veicolo?',
  edit: 'Modifica',
  editActivities: 'Modifica attività',
  employee: 'Addetto',
  userBackOffice: 'back office',
  employeeEndDate: 'Data di fine del dipendente',
  goTo: 'vai a',
  employeeRequest: 'Richieste dipendenti',
  employees: 'Addetti',
  employeesAvailable: 'Addetti disponibili',
  employeesMissingClockout: 'Ai seguenti dipendenti manca un timestamp di scadenza',
  exitFromCopy: 'Esci dalla copia',
  extraOrdinaryHours: 'Straord (ore)',
  filterForEmployee: 'Dipendente',
  filterForProject: 'Commessa',
  filterForTeam: 'Squadra',
  from: 'dal',
  createdAt: 'Creato il',
  createdBy: 'Creato da',
  lastUpdate: 'Ultimo aggiornamento',
  lastUpdateAt: 'Data ultimo aggiornamento',
  lastUpdateBy: 'Ultimo aggiornamento di',
  fromToDateError: 'La data inizio non può essere successiva alla data di fine',
  fullDay: '8',
  gasLiters: 'Gas rifornito (litri)',
  exportReport: 'Genera report',
  writeToDiskReport: 'Invia report',
  report: 'Report',
  generateReport: 'Genera',
  getReport: 'ottenere rapporto',
  goToEmployeeApp: 'Vai all\'app dei dipendenti',
  backToLogin: 'Torna alla login',
  hello: 'Ciao',
  hours: 'Ore',
  idLetter: 'Lettera identificativa',
  ifDriver: 'Se autista',
  in: 'Entrata',
  individualReport: 'Rapporto individuale',
  individualReportShort: 'Singolo',
  allEmployeeReport: 'Indietro alla vista globale',
  all: 'Tutti',
  insufficientTonsError: 'Errore: tonnellate insufficienti',
  exceededTonsError: 'Errore: ha superato le tonnellate consentite',
  layoff: 'Cassa integrazione',
  layoffHours: 'Cassa integrazione (ore)',
  letter: 'Lett. identif.',
  license: 'Targa',
  link: 'link',
  load: 'Carico',
  location: 'Posizione',
  localUnit: 'Unità Locale',
  localUnitProject: 'Unità Locale/Commessa',
  login: 'Accedi',
  logout: 'Logout',
  lunchBreak: 'Pausa pranzo',
  maxBreakTime: 'Pausa pranzo non eseguita',
  maxBreakTimeInterval: 'Massimo intervallo:',
  minutes: 'minuti',
  m3: 'm³',
  mainProject: 'Principale',
  maintenance: 'Manutenzione',
  maintenanceVehicleCode: 'Codice mezzo manutenzione',
  admin: 'TGI',
  registry: 'Anagrafiche',
  manageAccounts: 'Account',
  manageProjects: 'Commesse',
  manageCategories: 'Impianti',
  manageVehicles: 'Attrezzature',
  manageSquads: 'Squadre',
  manageLocalUnits: 'Unità locali',
  manageManufacturers: 'Produttore / Trasportatore',
  manageProperties: 'Properties',
  manageRecalculate: 'Ricalcola',
  manageConfigurations: 'Configurations',
  key: 'Key',
  value: 'Value',
  newButton: 'Nuovo',
  managePiles: 'Gestione cumuli',
  managingTimeCardFor: 'Timbratura per',
  manufacturer: 'Produttore',
  milling: 'Fresatura',
  millingHours: 'Ore Fresatura',
  model: 'Modello',
  monthly: 'mese',
  morning: 'Mattina',
  afternoon: 'Pomeriggio',
  name: 'Nome',
  property: 'Property',
  newActivity: 'Nuova attività',
  newCategory: 'Nuovo impianto',
  newLocalUnit: 'Nuova unità locale',
  newManufacturer: 'Nuovo produttore / trasportatore',
  newProperty: 'Nuova property',
  newSupplier: 'Nuovo fornitore',
  newPile: 'Crea nuovo cumulo',
  newRequest: 'Nuova richiesta',
  editRequest: 'Modifica richiesta',
  nightHours: 'Ore notturne',
  no: 'No',
  noBreak: '0 continuato',
  noDataFound: 'Non ci sono dati per i filtri selezionati',
  loading: 'Caricamento in corso...',
  noCarrierFoundWThatName: 'Nessun trasportatore trovato con quel nome',
  noEmployeesFoundWThatName: 'Nessun dipendente trovato con quel nome',
  noItemsFound: 'Nessun elemento trovato',
  noLocalUnitFoundWThatName: 'Nessuna Unita\' locale trovata con quel nome',
  noManufacturerFoundWThatName: 'Nessun produttore trovato con quel nome',
  noPartsAvailable: 'Nessuna parte disponibile per questa commessa',
  noProjectsWithThatName: 'Nessuna commessa valida per il testo inserito',
  noSchedulesCreatedOnDate: 'Nessuna pianificazione visibile in questa data',
  notAvailable: 'Non disponibile',
  notConfirmed: 'Non confermati',
  notes: 'Note',
  noVehiclesWithThatCode: 'Nessuna attrezzatura con quel codice interno',
  number: 'n.',
  oneHour: '1 ora',
  optionalNoteForRequest: 'Aggiungi una nota (facoltativo)',
  ordinaryHours: 'Ordinarie (ore)',
  out: 'Uscita',
  overlappingDatesError: 'Impossibile salvare l\'attività con date sovrapposte',
  generalError: 'Errore durante la comunicazione con il server, prego riprovare più tardi',
  part: 'Parte',
  parts: 'Parti',
  subPart: 'Sotto parte',
  subParts: 'Sotto parti',
  password: 'Password',
  pending: 'In attesa',
  in_progress: 'In carico',
  processed: 'Elaborato',
  approved: 'Approvato',
  rejected: 'Negato',
  standby: 'Stand by',
  closed: 'Chiuso',
  pileDetail: 'Cumuli - Impianto di ',
  piles: 'Cumuli',
  plantDestination: 'Destinatario',
  plants: 'Impianti',
  pleaseCheckBackInMinutes: 'Per favore ricontrolla tra un paio di minuti',
  print: 'stampa',
  project: 'Commessa',
  projects: 'Commesse',
  quantityExpected: 'Quintali previsti',
  quantity: 'Quantità',
  referenceTurn: 'Turno di riferimento',
  refuel: 'Rifornimento',
  registryId: 'N° Registro',
  remainder: 'Residuo',
  remainderWithUnload: 'Residuo (Scarico)',
  rememberMe: 'Ricorda il nome utente',
  removeProject: '-',
  removeSchedule: 'rimuovi',
  repair: 'Riparazione',
  request: 'Richiesta',
  handleRequest: 'Gestisci richiesta',
  changeRequest: 'Cambia stato richiesta',
  requestType: 'Tipo di richiesta',
  saveAllClockIns: 'Vuoi confermare tutte le timbrature?',
  saveError: 'Errore di salvataggio',
  saveNewPile: 'Crea Cumulo',
  createRecord: 'Crea nuovo record',
  scheduleTitle: 'Pianificazione',
  searchEmployee: 'search employee',
  search: 'ricerca',
  searchProject: 'Ricerca commessa',
  select: 'Seleziona',
  selectEmployeeForReport: 'Seleziona il dipendente per visualizzare il rapporto',
  selectReportType: 'Seleziona il tipo di rapporto',
  selectStartDate: 'Seleziona Data di Inizio',
  shiftWillEndNextDay: 'L\'attuale turno di lavoro terminerà il giorno successivo',
  special: 'SPECIAL',
  specialv2: 'SPECIALv2',
  standard: 'STANDARD',
  somethingWentWrong: 'Qualcosa è andato storto',
  status: 'Stato',
  statusFilter: 'Filtra per Stato',
  submit: 'Inizia',
  subpart: 'Sottoparte',
  suggestedProjects: 'Commesse suggerite',
  suppliersAvailable: 'Fornitori disponibili',
  t800: 'T800',
  thirtyMinutes: '30 minuti',
  time: 'Ora',
  timeReport: 'rapporto temporale',
  timeReportsTitle: 'Report ore lavorate',
  timesToCompare: 'Orari da Confrontare',
  to: 'al',
  toDate: 'Alla data',
  tons: '(tons)',
  total: 'Totale',
  totalAllowanceHours: 'Ore di indennità totale',
  totalAllowanceType: 'Tipo di indennità di ore totali',
  totalExtraOrdinaryHours: 'Tot straord (ore)',
  totalHours: 'Ore totali',
  totalOrdinaryHours: 'Tot ordinarie (ore)',
  twoDatesSelected: '2 date selezionate',
  type: 'Genere',
  typology: 'Tipologia',
  typeVehicleCode: 'Cerca mezzo',
  unload: 'Scarico',
  unloadedFrom: 'Scarico da',
  updateCertificate: 'aggiorna certificato',
  updatePile: 'Modifica cumulo',
  updateRecord: 'Aggiorna record',
  updateLocalUnit: 'Aggiorna unità locale',
  updateManufacturer: 'Aggiorna produttore / trasportatore',
  updateProperty: 'Aggiorna property',
  updateSupplier: 'Aggiorna fornitore',
  uploadCertificate: 'carica certificato',
  uploadCertificateTooltip: 'Carica Certificato',
  usedVan: 'Furgone usato',
  userHasBeenUpdated: 'l\'utente è stato aggiornato',
  username: 'Nome utente',
  email: 'Mail',
  changePasswordAtNextLogon: 'Cambio password al prossimo login',
  resetPassword: 'Reset password',
  employmentStartDate: 'Inizio rapporto',
  employmentEndDate: 'Fine rapporto',
  employeeNotActiveClockInExplain: 'Questo account non potrà accedere alle timbrature perchè la data di fine rapporto è stata superata',
  employeeNotActiveRequestExplain: 'Questo account non potrà effettuare richieste perchè la data di fine rapporto è stata superata',
  itsme: 'Sono io!',
  vacancyTime: 'Ferie / Assenza',
  van: 'Furgone',
  vanCode: 'Numero Furgone',
  vans: 'Furgone',
  vehicle: 'Veicolo',
  vehicleCode: 'Codice autocarro',
  vehicleNotInserted: 'Veicolo non inserito',
  activity: 'Attività',
  vehicleActivity: 'Attività attrezzature',
  vehicles: 'Mezzo',
  vehiclesAvailable: 'Mezzi disponibili',
  warningMissingTimeStamp: 'Avviso timbrature mancanti',
  weekly: 'sett.',
  workHours: 'Ore lavorate',
  yearly: 'anno',
  yes: 'Sì',
  youCanChangeStatusFrom: 'Puoi cambiare lo stato da',
  youCanChangeStatusTo: 'a',
  lunchTime0: '0 continuato',
  lunchTime30: '30 minuti',
  lunchTime60: '1 ora',
  lunchTime90: '1 ora 30 minuti',
  lunchTime120: '2 ore',
  reportPlan: 'Griglia pianificazione',
  reportplanPlainReport: 'Lista pianificazione',
  reportPlanningEmployee: 'Allocazione addetti',
  reportEventStore: 'Audit pianificazione',
  reportEmployeeTimeReport: 'Addetti',
  reportEmployeeTimeReportShort: 'Addetti',
  reportemployeeTimeReportReport: 'Addetti',
  reportemployeeTimeReportMiniReport: 'Addetti',
  reportclockInTransferReport: 'Timbrature',
  reportVehicleAllocationOperators: 'Attrezzature da operatori',
  reportVehicleAllocationShortOperators: 'Attrezzature da operatori',
  reportVehicleAllocationDrivers: 'Attrezzature da autisti',
  reportVehicleAllocationShortDrivers: 'Attrezzature',
  reportvehicleUsageReport: 'Attrezzature',
  reportvehicleUsageMiniReport: 'Attrezzature',
  reportVehicleGasUsage: 'Rifornimento gas',
  reportLongTermForeman: 'Pianificazione caposquadra',
  reportSecurityQuestion: 'Note sulla sicurezza',
  dpiVerification: 'Verifica DPI',
  materialUsed: 'Prodotto steso',
  tappeto: 'Tappeto',
  binder: 'Binder',
  base: 'Base',
  cementato: 'Cementato',
  stabilizzato: 'Stabilizzato',
  asphaltTemperature: 'Temperatura asfalto',
  environmentTemperature: 'Temperatura ambiente',
  manHolesVerification: 'Controllo chiusini e caditoie',
  other: 'Altro',
  longTermPlanner: 'Pianificatore a lungo termine',
  changeStatus: 'Cambia Stato',
  addTimeForEmployee: 'Aggiungi tempo per il dipendente',
  startDate: 'Data Inizio',
  endDate: 'Data Fine',
  removeSquad: 'rimuovi squadra',
  noAssignedEmployeesToSquad: 'Nessun dipendente assegnato in questa squadra',
  newSquad: 'Nuova squadra',
  whatNameForSquad: 'Che nome vorresti dare alla nuova squadra!',
  createNewSquad: 'crea nuova squadra',
  employeesPlural: 'Addetti',
  filterBySquadName: 'Filtra per nome della squadra',
  dateEndOfContract: 'Data fine lavori da contratto',
  totalAllocatedDays: 'Totale giorni allocati',
  squad: 'Squadra',
  updateNotes: 'aggiorna note',
  updateNotesFor: 'Note di aggiornamento per',
  typeSquadName: 'Digitare il nome della squadra',
  unableToUpdateTimeline: 'Impossibile aggiornare la sequenza temporale in questo momento',
  updateError: 'Errore di aggiornamento',
  timelineDateForEmployee: 'La data di pianificazione per il dipendente',
  exceedsEndDate: 'supera la data di fine rapporto',
  selectProductionCenter: 'Selezionare il centro di produzione',
  activeProductionCenters: 'Centri di produzione attivi',
  findProject: 'Trova progetto',
  changeProjectOrder: 'Cambia ordine di progetto',
  typeSupplierName: 'Digitare il nome del fornitore',
  noSupplierFoundWThatName: 'Nessun fornitore trovato con quel nome',
  addTimeForSupplier: 'Aggiungere tempo per il fornitore',
  supplier: 'Fornitore',
  suppliers: 'Fornitori',
  expand: 'Espandi',
  collapse: 'Riduci',
  noResultsFound: 'Nessun risultato trovato',
  noElementsYet: 'Nessun elemento presente',
  changeForemanCategory: 'Caposquadra',
  categoryField: 'Categoria',
  sortField: 'Oridinamento',
  selectCategory: 'Seleziona categoria',
  saved: 'Salvato',
  updated: 'Aggiornato',
  security: 'Sicurezza',
  enabled: 'Abilitato',
  securityType: 'Tipologia di riscontro',
  securityNote: 'Nota del report',
  securityType0: 'cantiere',
  securityType1: 'mezzi/attrezzature',
  securityType2: 'DPI',
  securityType3: 'mancato infortunio',
  teamType1: 'Autisti',
  teamType2: 'Operatori',
  cloneScheduleTitle: 'Seleziona range di date',
  newVersionTitle: 'È disponibile una nuova versione di TOM!',
  newVersionReload: 'Per favore clicca qui sotto e verifica l\'ultima operazione eseguita.',
  reloadPage: 'Aggiorna versione',
  preparation: 'Preparazione',
  preparationLong: 'Preparazione piano di posa asfalto',
  vehicleOperation_REP: 'Riparazione',
  vehicleOperation_SER: 'Tagliando',
  vehicleOperation_INS: 'Revisione',
  equipments: 'Attrezzature',
  equipment: 'Attrezzatura',
  equipmentCode: 'Codice attrezzatura',
  newsTitle: 'Bacheca',
  news: 'Annuncio',
  newsNew: 'Nuovo annuncio',
  editNews: 'Modifica annuncio',
  title: 'Titolo',
  image: 'Immagine',
  attachment: 'Allegato',
  attachments: 'Allegati',
  file: 'file',
  files: 'files',
  noFiles: 'nessun file',
  readBy: 'Letto da',
  notReadBy: 'Non letto da',
  details: 'Dettagli',
  max: 'Max',
  projectCategory: 'Commessa / Impianto',
  readColumn: 'Letto / Non letto',
  boots: 'Stivali',
  demolitionMan: 'Demolitore',
  confinedSpaces: 'Spazi confinati',
  deepExcavations: 'Scavi profondi',
  qualification: 'Qualifica',
  employeeType: 'Tipo',
  taxCode: 'Codice fiscale',
  phoneNumber: 'Numero di telefono',
  featureNotAvailable: 'Attualmente non disponibile',
  capabilities: 'Profili',
  'capabilityTitle Admin': 'Tech Gap admin',
  'capabilityDescr Admin': 'Diritti di admin per Tech Gap users (es: ricalcolo dati)',
  'capabilityTitle Activity Planner': 'Pianificazione',
  'capabilityDescr Activity Planner': 'Gestione pianificazione giornaliera e anagrafica impianti',
  'capabilityTitle Back Office': 'Back office',
  'capabilityDescr Back Office': 'Gestione richieste impiegati, veicoli e dpi, e reportistica',
  'capabilityTitle Reports': 'Timbrature',
  'capabilityDescr Reports': 'Gestione timbrature e reportistica',
  'capabilityTitle InertReports': 'Timbrature inerti',
  'capabilityDescr InertReports': 'Gestione timbrature e reportistica per il settore inerte',
  'capabilityTitle Long Term Planner': 'Pianificazione medio/lungo termine',
  'capabilityDescr Long Term Planner': 'Gestione pianificazione medio/lungo termine e anagrafica squadre',
  'capabilityTitle Manage piles': 'Cumuli',
  'capabilityDescr Manage piles': 'Gestione cumuli e anagrafiche unità locali / trasportatori',
  'capabilityTitle Manage news': 'Bacheca',
  'capabilityDescr Manage news': 'Gestione annunci nella bacheca',
  'capabilityTitle Manage CRUD': 'Amministrazione anagrafiche di base',
  'capabilityDescr Manage CRUD': 'Permette la gestione di Addetti, Commesse e Attrezzature',
  'capabilityTitle Manage account': 'Amministrazione degli account',
  'capabilityDescr Manage account': 'Permette la gestione degli Account di sistema',
  'capabilityTitle Reports Admin': 'Amministratore delle timbrature',
  'capabilityDescr Reports Admin': 'Permette di confermare tutte le timbrature',
  'capabilityTitle All Projects': 'Accesso a tutte le commesse',
  'capabilityDescr All Projects': 'Consente di visualizzare tutte le commesse presenti nel sistema',
  areYouSure: 'Sicuro di voler procedere?',
  city: 'Città',
  province: 'Provincia',
  clientCode: 'Cliente',
  latitude: 'Latitudine',
  longitude: 'Longitudine',
  supervisor: 'Supervisore',
  deliveryDate: 'Data Consegna',
  expirationDate: 'Data scadenza',
  effectiveDays: 'Giorni Utili',
  productionCenterCode: 'Codice Centro di Produzione',
  productionCenterDescription: 'Descrizione Centro di Produzione',
  newAccountEmployee: 'Nuovo account / addetto',
  updateAccountEmployee: 'Modifica account / addetto',
  sectionDates: 'Date',
  sectionProductionCenter: 'Centro di produzione',
  newProject: 'Nuova commessa',
  updateProject: 'Aggiorna commessa',
  newVehicle: 'Nuovo attrezzatura',
  updateVehicle: 'Modifica attrezzatura',
  frame: 'Frame',
  codeOrig: 'Code orig',
  label: 'Nome',
  showEmployeeData: 'Abilita addetto',
  validationRequired: 'Valore obbligatorio',
  validationEmail: 'Il formato mail non è valido',
  validationTaxCode: 'Il formato del codice fiscale non è corretto',
  validationOnlyDigits: 'Sono permessi solo numeri',
  validationAlreadyExists: 'Attenzione: valore già stato utilizzato',
  validationJSONNotValid: 'JSON non corretto',
  vehicleRequest: 'Richieste attrezzature',
  save: 'Salva',
  saveAndApprove: 'Salva & approva',
  dpi: 'DPI',
  accountProject: 'Account-Commesse',
  newAccountProject: 'Nuova relazione Account-Commesse',
  editAccountProject: 'Modifica relazione Account-Commesse',
  noAccountFoundWThatName: 'Nessun account trovato con quel nome',
  accountFilterPlaceHolder: 'Filtra per account',
  available: 'Disponibili',
  assigned: 'Assegnate',
  projectSummary: 'Sintesi della commessa',
  selectProjectToSeeResults: 'Seleziona una commessa per vedere i risultati',
  transfer: 'Trasferimento',
  workMinutes: 'Minuti Lavorati',
  noSupervisor: 'Nessun supervisore',
  selectSupervisor: 'Seleziona il supervisore',
  showHidePrograms: 'Mostra/Nascondi Commesse',
  priority: 'Priorità',
  priority_0: 'Bassa',
  priority_1: 'Media',
  priority_2: 'Alta',
  plannedDate: 'Data prevista',
  originalTimestamp: 'Ora originale',
  jobDescription: 'Lavori svolti',
  nextSchedule: 'Prossime pianificazioni',
  nextScheduleElements: 'Elementi',
  nextScheduleOption_time: 'Ora d\'inizio',
  nextScheduleOption_where: 'Dove',
  nextScheduleOption_supervisor: 'Supervisore progetto',
  nextScheduleOption_vehicles: 'Lista veicoli pianificati',
  nextScheduleOption_notes: 'Note',
  nextScheduleOption_squad: 'Mostra squadra di lavoro',
  nextScheduleOption_squadVehicles: 'Mostra i veicoli della squadra di lavoro',
  nextScheduleHour: 'Mostra dopo le',
  nextScheduleOccurrences: 'Massimo di giorni da mostrare',
  nextScheduleSolarDays: 'Giorni solari',
  nextScheduleAlwaysShow: 'Mostra sempre prossima schedulazione',
  usedEquipmentInShift: 'Hai usato un\'attrezzatura in questo turno?',
  sameDestinationQuestion: 'Destinazione uguale alla pianificazione?',
  noteQuestion: 'Vuoi aggiungere una nota alla timbratura?',
  equipmentQuestion: 'Sono state usate delle attrezzature?',
  didDpi: 'Vuoi richiedere DPI / Minuterie',
  closeProgramHeader: 'Close program header',
  fixedProjectScheduleHeight: 'Fixed project schedule height',
  plannerResourcesSelectedOnBottom: 'Planner resources selected on bottom',
  showProjectDescriptionInSchedule: 'Show project description in schedule',
  useBiggerFontInSchedule: 'Use bigger front in schedule',
  mobileDriverShowOperatorButton: 'Mostra bottone dell\'operatore',
  mobileDriverShowMillingButton: 'Mostra bottone della fresatura',
  mobileDriverShowDDTButton: 'Mostra bottone DDT',
  mobileDriverShowPreparationButton: 'Mostra bottone preparazione',
  mobileShowDocuments: 'Mostra documenti',
  mobileShowNews: 'Monstra le news',
  mobileShowPayroll: 'Mostra il payroll',
  plannerCopyWholeDay: 'copia intera pianificazione',
  clonePlanWarning: 'Attenzione: tutta la pianificazione della \'data destinazione\' verrà eliminata!',
  dateToProgramNotInPast: 'Data destinazione non può essere una data nel passato',
  adjustBreakTime: 'Aggiustare ora d\'inizio',
  breakTimeError: 'Errore di aggiornamento: l\'ora di inizio supera l\'ora d\'uscita',
  inertClockInTitle: 'Timbrature Inerti',
  sector: 'Settore',
  filterForSector: 'Settore',
  reportInertEmployeeTimeReportShort: 'Addetti',
  activityHours: 'Ore attività',
  activityNotes: 'Note attività',
  addSectorDialog: 'Aggiungi Settori e Attività',
  selectYourSector: 'Seleziona il settore di competenza',
  selectYourActivity: 'Seleziona l\'attività svolta',
  howLongWasYourLunchBreak: 'Quanto è durata la tua pausa pranzo?',
  family: 'Famiglia'
}
